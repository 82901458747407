:root {
    --sky-blue: #18b6e4;
    --status-red:  #e9675a;
    --danger-red: #d9534f;
    --success-green: #5cb85c;
    --dark-gray: #898989;
}
/**********************************************************************************************************
 * Login Page Colors
 **********************************************************************************************************/
@sky-blue: var(--sky-blue);
@dark-gray: var(--dark-gray);
@alert-red: var(--status-red);
@success: var(--success-green);
@danger: var(--danger-red);
@light-gray: #eae6e1;
@text-color-dark-gray: #596679;
@highlighted-blue: darken(#18b6e4, 5%);

/* These colours match the default colours for bootstrap alerts */
@alert-danger-text: #a94442;
@alert-danger-background: #f2dede;
@alert-danger-border: #ebccd1;