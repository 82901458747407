@danger-red: #FD7766;

.mypay {

    .header-background {
        width: 100%;
        height: 200px;
    }

    .title {
        position: absolute;
        z-index: 125;
        left: 50%;
        margin-left: -105px;
        width: 212px;
        top: 50px;

        .logo {
            display: inline-block;
            width: 50px;
            height: 50px;
            vertical-align: bottom;
        }

        h1 {
            display: inline-block;
            font-size: 50px;
            color: white;
            font-weight: 500;
        }
    }

    .subtitle {
        position: absolute;
        z-index: 125;
        left: 50%;
        margin-left: -150px;
        width: 300px;
        top: 50px;
        font-size: 21px;
        color: white;
        text-align: center;
        top: 120px;
    }

    .footer {
        position: relative;
        height: 150px;

        .app-download-link {
            position: absolute;
            top: 40px;
            bottom: 35px;
            text-align: center;
            width: 200px;
            margin-left: -100px;
            left: 50%;

            p {
                font-weight: 600;
                padding-bottom: 10px;
            }

            .ios-link {
                img {
                    width: 150px;
                }
            }

            .android-link {
                img {
                    width: 170px;
                }
            }
        }
    }

    form {
        width: 250px;
        text-align: center;
        margin-left: -125px;
        left: 50%;
        margin-top: 30px;
        position: relative;

        label {
            font-weight: 600;
        }

        .error-message {
            font-size: 12px;
            text-align: left;
            padding-top: 3px;
            color: @danger-red;
        }

        .input-invalid {
            border-color: @danger-red;
        }

        .help-icon {
            right: 0;
            position: absolute;
        }

        .tooltip-inner {
            text-align: left;
            padding: 10px;
        }
    }

    button {
        border-radius: 37px;
        background-image: -moz-linear-gradient( 0deg, rgb(2,119,255) 0%, rgb(52,170,220) 100%);
        background-image: -webkit-linear-gradient( 0deg, rgb(2,119,255) 0%, rgb(52,170,220) 100%);
        background-image: -ms-linear-gradient( 0deg, rgb(2,119,255) 0%, rgb(52,170,220) 100%);
        box-shadow: 0px 9px 10px 0px rgba(56, 56, 56, 0.15);
        color: white;
        font-weight: 600;
        width: 250px;
        margin-top: 20px;
        height: 40px;
        border-color: transparent;
        background-origin: border-box;
    }

    .mypay-account-success {
        img {
            width: 100px;
            left: -50px;
            margin: 50px;
            margin-left: 50%;
            position: relative;
        }
    }

    .success-message, .message-block {
        font-weight: 600;
        padding: 0 20px;
        text-align: center;
        line-height: 25px;
    }

    .message-block {
        padding-top: 20px;
    }
}
