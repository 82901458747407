/**********************************************************************************************************
 * LESS Styling the Login Page ( and ResetPwd, etc)
 * created by Ryan Tao
 **********************************************************************************************************/
//Font Sizes
@title-font-size: 30px;
@button-font-size: 18px;
@field-font-size: 16px;
@text-font-size: 16px;
@mfa-instruction-font-size: 14px;
@mfa-button-font-size: 16px;
@text-color-dark-gray: #596679;
@success-text-colour: #155724;

//Widths
@direct-access-logo-width: 30%;
@store-icon-width: 21%;

//Paddings & Margins
@xl-static-gap: 40px;
@l-static-gap: 20px;
@m-static-gap: 10px;
@s-static-gap: 5px;
@l-dynamic-gap: 20%;
@m-dynamic-gap: 10%;
@s-dynamic-gap: 5%;

//Mixin for login-row
.login-row-mixin(@width-percent: 20%) {
    .login-row {
        margin: 0 (100%-@width-percent)/2;
        width: @width-percent;
    }
}

//Mixin for message-row
.message-row-mixin(@width-percent: 40%) {
    .message-row {
        margin: 0 (100%-@width-percent)/2;
        width: @width-percent;
    }
}

//Mixin for mfa-container
.mfa-row-mixin(@width-percent: 60%){
    .mfa-row {
        margin: 0 (100%-@width-percent)/2;
        width: @width-percent;
    }
}

/***********************************************************************************************************/

body{
    font-family: @font-family;
}

.login-container {
    background-color: white;
    height: 100%;
    width: 100%;
    text-align: center;
}

.mfa-container {
    background-color: white;
    width: 100%;
    text-align: center;
}

.switch-accessibility {
    margin-top: 50px;
    color: @text-color-dark-gray;
    font-size: 19px;
    font-weight: bold;
    text-align: left;
    
    input {
        width: 100%;
        height: 40;
        top: -10;
        left: -10;
        position: absolute;
    }
}

#system-requirements-container{
    top: 20%;
    position:absolute;
}

.login-row-mixin();
.message-row-mixin();
.mfa-row-mixin();

.datacom-payroll-logo {
    padding-top: @l-dynamic-gap;
}

.direct-access-logo {
    max-width: @direct-access-logo-width;
    min-width: 64px;
    padding-top: @l-static-gap;
}

.direct-access-title {
    padding: 20px 0px (20px + 3%) 0px;
    .font(30px, @sky-blue);
}

.login.form-control {
    background-color: @light-gray;
    height: 6%;
    margin-top: @s-dynamic-gap;
    min-height: 36px;
    .font(@field-font-size, @dark-gray) !important;
}

input {
    &:-webkit-autofill {
        background-color: @light-gray !important;
        -webkit-box-shadow: 0 0 0px 1000px @light-gray inset;
    }
}

.submit-button {
    border: 0px;
    background: @sky-blue;
    margin-top: @m-dynamic-gap;
    padding: 15px;
    .font(@button-font-size, white);

    &:hover, &:active, &:focus {
        background: @highlighted-blue;
    }
}

.sub-link {
    margin-top: @l-static-gap;
    a {
        .font(@text-font-size, @dark-gray);
        cursor: pointer;
    }
}

.download-text{
    margin-top: @xl-static-gap;
    .font(@text-font-size, @sky-blue);
}
.app-download-logo {
    margin: @m-static-gap @s-static-gap;
    max-width: @store-icon-width;
}
.browser-logo{
    max-width: 25%;
}

.da-xl-static-gap {
    height: @l-static-gap
}

// Warning box
.login.alert-danger {
    background-color: @alert-red;
    .font(@field-font-size, white);
}


// For new password and reset password
.message-border {
    border: 1px solid @dark-gray;
    padding: @m-static-gap;
    .font(@text-font-size, @dark-gray);
}

// reCaptcha widget
.g-recaptcha {
    width: 86%;
    border-radius: 4px;
    border-right: 1px solid #d8d8d8;
    overflow: hidden;
    transform:scale(1.17);
    transform-origin:0 0;
}

.qr-spinner {
    padding-left: 50%;
}

.auth-input-spinner {
    padding-top: 58px;
}

.success-font {
    color: @success;
}

.fail-font {
    color: @danger;
}

.mfa-title {
    font-size: @mfa-button-font-size;
    font-weight: bold;
    color: @text-color-dark-gray;
    padding-top: @l-static-gap;
    padding-bottom: @l-static-gap;
}

.mfa-header {
    font-size: @mfa-button-font-size;
    font-weight: bold;
    color: @text-color-dark-gray;
    padding-top: @l-static-gap;
    padding-bottom: @l-static-gap;
    height: 40px;
}

.mfa-loading-overlay{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    background: white;
    z-index: 999;
}

.mfa-text {
    padding: 20px 0;
    font-size: @mfa-instruction-font-size;
    color: @text-color-dark-gray;
    line-height: 1.5;
}

.mfa-instruction-text {
    font-size: @mfa-instruction-font-size;
    color: @text-color-dark-gray;
    line-height: 1.5;
}

.mfa-link {
    text-decoration: underline;
    margin-left: 5px;
    margin-right: 5px;
}

.mfa-setup-btn {
    padding: 15px;
    font-size: @mfa-button-font-size;
    color: @text-color-dark-gray;
    font-weight: bold;
}

.mfa-skip-btn {
    border: 0px;
    background: @sky-blue;
    margin-top: 20px;
    padding: 10px 15px;
    .font(@mfa-button-font-size, white);

    &:hover, &:active, &:focus {
        background: @highlighted-blue;
    }
}

.mfa-primary-btn {
    border-color: @sky-blue;
    background: @sky-blue;
    margin-top: 20px;
    padding: 10px 15px;
    .font(@mfa-button-font-size, white);

    &:hover, &:active, &:focus {
        background: @highlighted-blue;
        border-color: @highlighted-blue;
    }
}

.mfa-devices-logo {
    height: 200px;
    width: 200px;
}

.mfa-default-btn {
    margin-top: 20px;
    padding: 10px 15px;
    font-size: @mfa-button-font-size;
}

.mfa-refresh{
    color: @dark-gray;
    cursor: pointer;
    text-decoration: underline;
}

.tooltip {
    width: 100%;
}

.mfa-img-container {
    width: 75px;
    height: 75px;
}

/* resize images */
.mfa-img-container img {
    width: 100%;
    height: auto;
}

.mfa-register-container{
    margin-left: 30%;
    width: 40%;
}

.mfa-offline-step {
    padding-bottom: 20px;
}

.mfa-offline-step-content {
    padding-top: @l-static-gap;
}

.mfa-nav-buttons {
    border-top: 1px solid;
    border-color: @text-color-dark-gray;
}

.danger-text {
    color: @alert-danger-text;
}

.bold-link {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
}

.login-auth-spinner{
    float: left;
    padding-top: 38px;
    padding-left:20px;
}

.setup-indicator{
    float: right;
    width: 30px;
    padding-top: 15px;
    margin-right: -30px;
}

.mfa-resend-dialog{
    width: 330px;
    margin-left: auto;
    margin-right: auto;
}

.mfa-success-dialog{
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    color: @success-text-colour;
    font-size: @mfa-instruction-font-size;
    margin-top: 25px;
    margin-bottom: 30px;
}

.mfa-continue-button {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border: 0px;
    background: @sky-blue;
    padding: 15px;
    .font(@button-font-size, white);

    &:hover, &:active, &:focus {
        background: @highlighted-blue;
    }
}

.mfa-checkbox {
    height: 30px;
    margin-left: 12px;
    label {
        font-weight: normal;
        width: 200px;
        color: @dark-gray;
        font-size: 16px;
        margin: 0 auto;
    }

    input {
        margin-top: 1px;
    }
}

.mfa-security-questions {
    padding-top: 20px;

    .form-group {
        width: 100%;
    }

    &.login-auth-spinner {
        width: 100%;
        padding-left: 50%;
    }
}

.mfa-alert {
    &.alert-message {
        display: inline-block;
        padding: 9px;
        margin: 0;

        background: @alert-danger-background;
        border-color: @alert-danger-border;
        color: @alert-danger-text;

        border: 1px solid transparent;
        border-radius: 4px;

        font: inherit;
    }
}

.login-auth-input-indicator{
    float: left;
    width: 30px;
}

.login-pincode-container{
    float: left;
}

.no-padding {
   padding: 0 !important;
   margin: 0 !important;
}

@media (max-width: 1600px) {
    .mfa-register-container{
        margin-left: 25%;
        width: 50%;
    }
}

@media (max-width: 1200px) {

    .login-row-mixin(24%);
    .message-row-mixin(50%);
    .mfa-row-mixin(70%);

    .login.form-control {
        font-size: @field-font-size - 2;
    }

    .submit-button {
        font-size: @button-font-size - 2;
    }

    .direct-access-title {
        font-size: @title-font-size - 5;
    }

    .sub-link>a, .download-text{
        font-size: @text-font-size - 2;
    }

    .mfa-text{
        font-size: @mfa-instruction-font-size - 2;
    }

    .mfa-title, .mfa-setup-btn, .mfa-primary-btn, .mfa-default-btn{
        font-size: @mfa-button-font-size - 2;
    }
}


@media (max-width: 992px) {

    .login-row-mixin(30%);
    .message-row-mixin(70%);
    .mfa-row-mixin(90%);

    .direct-access-title{
        padding: @m-static-gap 0;
    }

    .mfa-text{
        font-size: @mfa-instruction-font-size - 2;
    }

    .mfa-title, .mfa-setup-btn, .mfa-primary-btn{
        font-size: @mfa-button-font-size - 2;
    }
}


@media (max-width: 768px) {

    .login-row-mixin(60%);
    .message-row-mixin(100%);
    .mfa-row-mixin(100%);

    .direct-access-logo{
        max-width: @direct-access-logo-width - 10%;
    }

    .direct-access-title {
        font-size: @title-font-size - 3;
    }

    .datacom-payroll-logo {
        padding-top: @l-dynamic-gap - 5%;
    }

    .mfa-img-container {
        width: 50px;
        height: 50px;
        margin-top: @s-static-gap;
    }

    .mfa-text{
        font-size: @text-font-size - 2;
        padding: @m-static-gap;
    }

    .mfa-title, .mfa-setup-btn, .mfa-primary-btn{
        font-size: @mfa-button-font-size - 2;
    }

    .mfa-register-container{
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        margin: 0;
        width: 100%;
    }

    .login-pincode-container{
        width: 50%;
        margin-left: 25%;
    }
}

@media (max-width: 584px) {
    .g-recaptcha {
        width: 100%;
        transform: scale(1);
    }
    .browser-logo{
        max-width: 65%;
    }
    #system-requirements-container {
        top: 10%;
    }
}

@media (max-width: 374px) {
    .g-recaptcha {
        width: 125%;
        transform: scale(0.8);
    }
}

#browser-table{
    .browser-description{
       vertical-align: middle;
    }
}

