/* Input validation styling */
@info-circle-icon-color: #337AB7;

.fa-exclamation-circle,
.fa-exclamation-triangle {
    display: block;
    vertical-align: middle;
    font-size: 1.3em;
    top: 0;
    right: 0;

    &:hover {
        text-decoration: none;
        position: absolute;
    }
}

.fa-exclamation-circle {
    &:hover {
        color: red;
    }

    color: red;
}

.fa-exclamation-triangle {
    &:hover {
        color: #FEAE1B;
    }

    color: #FEAE1B;
}

.fa-info-circle {
    &:hover {
        color: @info-circle-icon-color;
    }

    color: @info-circle-icon-color;
}

.alert-danger,
.alert-warning {
    padding: 8px;
}

.alert-danger,
.alert-warning {
    margin-top: 20px;
}

.mfa-alert-danger {
    display: none;
}

.message-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
}

.warning-message-container {
    display: none;
}

/*Date picker style*/
.datepicker-switch,
.datepicker table tr th {
    font-weight: 700 !important;
}

/* Time picker styling */
.bootstrap-timepicker-hour,
.bootstrap-timepicker-minute,
.bootstrap-timepicker-meridian {
    padding: 0px;
    display: inline;
}

.bootstrap-timepicker-widget table td input {
    width: 35px;
}

.bootstrap-timepicker-widget table td a {
    color: @text-color-dark-gray;
}

.btn-circle {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 6px 0;
    font-size: 16px;
    line-height: 1;
    border-radius: 50%;
}

.select-view-input-error {
    float: right;
    position: absolute;
    display: none;
}

.select-view-alert {
    z-index: 999;
    position: absolute;
    right: 0;
}

.form-group {
    label {
        float: left;
        padding-bottom: 5px;
        .font(14px, @text-color-dark-gray, 700);
    }

    input {
        .font(14px);
    }

    .bootstrap-select {
        .selectpicker {
            width: 100%;
            padding-right: 25px;

            .filter-option {
                display: inline-block;
                overflow: hidden;
                width: 100%;
                text-align: left;
                .font(14px);
            }
        }

        .dropdown-menu {
            min-width: 100%;

            .inner {
                position: static;
                border: 0;
                padding: 0;
                margin: 0;
                border-radius: 0;
                -webkit-box-shadow: none;
                box-shadow: none;

                li {
                    a {
                        display: block;
                        padding: 3px 20px;
                        line-height: 1.42857143;
                        white-space: nowrap;
                        clear: both;
                        cursor: pointer;

                        span {
                            .font(14px);
                        }

                        .check-mark {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/* Pincode input styling */
.pincode-input-container {
    display: inline-block;
    padding: 0;

    .pincode-input-text {
        display: inline-block;
        width: 15%;
        text-align: center;
        padding: 0;

        &.first {
            border-radius: 4px 0px 0px 4px;
        }

        &.mid {
            border-radius: 0;
        }

        &.last {
            border-radius: 0px 4px 4px 0px;
        }
    }
}

.input-indicator {
    height: 60px;

    i {
        font-size: 30px;
    }
}

.input-required:after {
    color: #e32;
    content: ' *';
    display: inline;
}
